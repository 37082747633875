import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { PaperPlaneTilt } from 'phosphor-react';
import { AnimatePresence, motion } from 'framer-motion';

const MotionAlert = motion(Alert);

const validationSchema = yup.object({
	email: yup.string().email('Un email valide est requis.').required('Un email valide est requis.'),
});

const Newsletter = () => {
	const [ loading, setLoading ] = useState(false);
	const [ state, setState ] = useState(null);

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema,
		onSubmit: async values => {
			setLoading(true);

			try {
				const req = await fetch('/api/newsletter', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(values),
				});

				if (req.ok) {
					const res = await req.json();
					if (res.error === null) {
						setState('success');
					}
					else {
						setState('error');
						setTimeout(() => {
							setState(null);
						}, 4000);
					}
				}
				else {
					setState('error');
					setTimeout(() => {
						setState(null);
					}, 4000);
				}
			}
			catch(err) {
				setState('error');
				setTimeout(() => {
					setState(null);
				}, 4000);
			}

			setLoading(false);
		}
	});

	return (
		<Paper component="form" onSubmit={formik.handleSubmit} elevation={2} sx={{ position: 'relative', p: 3, mx: { xs: 0, lg: 4 } }}>
			<Typography variant="h5" sx={{ textAlign: 'center' }}>
				S'inscrire à la newsletter
			</Typography>

			<Box sx={{ px: 2 }}>
				<TextField
					name="email"
					label="Votre email"
					variant="standard"
					type="email"
					required
					fullWidth
					value={formik.values.email}
					onChange={formik.handleChange}
					error={formik.touched.email && Boolean(formik.errors.email)}
					helperText={formik.touched.email && formik.errors.email}
					onKeyDown={e => {
						if (e.code === 'Enter' && !loading && formik.isValid && formik.dirty) {
							console.log('Enter');
							formik.handleSubmit();
						}
					}}
					InputProps={{
						endAdornment: <InputAdornment position="start">
							<IconButton type="submit" disabled={loading || !(formik.isValid && formik.dirty)} edge="end">
								{loading ? <CircularProgress size={20} thickness={1} /> : <PaperPlaneTilt weight="thin" />}
							</IconButton>
						</InputAdornment>,
					}}
				/>
			</Box>

			<Typography variant="overline" sx={{ display: 'block', textAlign: 'center', mt: 4, lineHeight: 1.4 }}>
				Abonnez-vous à notre newsletter afin de recevoir directement dans votre boîte mail les nouvelles collections de JOYAA.
			</Typography>

			<AnimatePresence>
				{state === 'success' ? (
					<MotionAlert
						key="success"
						initial={{ opacity: 0, filter: 'blur(5px)' }}
						animate={{ opacity: 1, filter: 'blur(0px)' }}
						exit={{ opacity: 0, filter: 'blur(5px)' }}
						severity="success"
						sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						Votre inscription à la newsletter a bien été enregistrée, merci !
					</MotionAlert>
				) : state === 'error' ? (
					<MotionAlert
						key="error"
						initial={{ opacity: 0, filter: 'blur(5px)' }}
						animate={{ opacity: 1, filter: 'blur(0px)' }}
						exit={{ opacity: 0, filter: 'blur(5px)' }}
						severity="error"
						sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						Erreur lors de l'enregistrement. Merci de bien vouloir réessayer.
					</MotionAlert>
				) : null}
			</AnimatePresence>
		</Paper>
	);
};

export default Newsletter;
