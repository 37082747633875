import React, { Suspense, useState } from 'react'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { AnimatePresence, motion } from 'framer-motion';
import { Engine, Model, Scene } from 'react-babylonjs'
import { Color4 } from '@babylonjs/core/Maths/math.color';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';

const MotionBox = motion(Box);

const BabylonScene = ({ model }) => {
	const [ loading, setLoading ] = useState(true);

	return (
		<Box sx={{ position: 'relative', my: 3, aspectRatio: '16/10' }}>
			<Engine antialias adaptToDeviceRatio canvasId="babylonJS">
				<Scene clearColor={new Color4(0, 0, 0, 0)}>
					<arcRotateCamera
						name="Camera"
						target={Vector3.Zero()}
						alpha={Math.PI / 2}
						beta={Math.PI / 4}
						radius={128}
						lowerRadiusLimit={64}
						upperRadiusLimit={256}
					/>
					<hemisphericLight
						name="Light"
						intensity={0.7}
						direction={Vector3.Up()}
					/>
					<Suspense fallback={<></>}>
						<Model rootUrl="/" sceneFilename={model} onModelLoaded={() => setLoading(false)} />
					</Suspense>
				</Scene>
			</Engine>

			<AnimatePresence initial={false}>
				{loading && (
					<MotionBox
						key="loading"
						initial={{ opacity: 1, backdropFilter: 'blur(10px)' }}
						animate={{ opacity: 1, backdropFilter: 'blur(10px)' }}
						exit={{ opacity: 0, backdropFilter: 'blur(0px)' }}
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							position: 'absolute',
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							zIndex: 10,
						}}
					>
						<CircularProgress />
					</MotionBox>
				)}
			</AnimatePresence>
		</Box>
	);
}

export default BabylonScene;
