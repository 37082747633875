import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { InstagramLogo} from 'phosphor-react';
import ContactForm from './ContactForm';
import LegalNotices from './LegalNotices';
import PrivacyPolicy from './PrivacyPolicy';
import ShippingReturn from './ShippingReturn';

const copyrightDate = () => {
	const d = (new Date()).getFullYear();
	return d === 2022 ? d : `2021 - ${d}`;
};

const Footer = () => {
	const [ contactFormOpen, setContactFormOpen ] = useState(false);
	const [ legalNoticesOpen, setLegalNoticesOpen ] = useState(false);
	const [ privacyPolicyOpen, setPrivacyPolicyOpen ] = useState(false);
	const [ shippingReturnOpen, setShippingReturnOpen ] = useState(false);

	return (
		<>
			<Paper
				component="footer"
				sx={{
					position: 'relative',
					background: '#212428',
					px: { xs: 2, md: 4, lg: 6 },
					py: { xs: 3, md: 5, lg: 6 },
					borderRadius: 0,
					textAlign: 'center',
					color: '#FAFAFA',
					overflow: 'hidden',
					zIndex: 100,

					'&:before': {
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						height: '4px',
						background: '#8B7B60',
						content: '""',
						zIndex: 101,
					},

					'&:after': {
						position: 'absolute',
						top: 4,
						left: 0,
						right: 0,
						height: '3px',
						background: '#FAFAFA',
						content: '""',
						zIndex: 101,
					},
				}}
			>
				<Box sx={{ display: 'flex', flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
					<Box sx={{ flex: { xs: '1 1 50%', sm: '1 1 25%' }, textAlign: 'left' }}>
						<MenuList sx={{ display: 'inline-block', width: 200 }}>
							<MenuItem onClick={() => setContactFormOpen(true)} sx={{ fontSize: '0.9rem' }}>Contact</MenuItem>
							<MenuItem onClick={() => setLegalNoticesOpen(true)} sx={{ fontSize: '0.9rem' }}>Mentions légales</MenuItem>
							<MenuItem onClick={() => setPrivacyPolicyOpen(true)} sx={{ fontSize: '0.9rem' }}>Politique des données</MenuItem>
							<MenuItem onClick={() => setShippingReturnOpen(true)} sx={{ fontSize: '0.9rem' }}>Livraison & retour</MenuItem>
						</MenuList>
					</Box>

					<Box
						sx={{
							flex: { xs: '1 1 100%', sm: '1 1 50%' },
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							opacity: 0.6,
							order: { xs: 3, sm: 0 },
							mt: { xs: 4, sm: 0 },
						}}
					>
						<Typography variant="overline" display="block">
							DESIGNED IN GENEVA - SWITZERLAND
						</Typography>

						<Typography variant="overline" display="block">
							JOYAA &copy; {copyrightDate()}
						</Typography>

						<Typography variant="body2" display="block" mt={2}>
							<small>Développé par <a href="https://nammu.ch/" target="_blank" rel="noreferrer" style={{ color: '#FAFAFA' }}>nammu.ch</a></small>
						</Typography>
					</Box>

					<Box sx={{ flex: { xs: '0 1 33%', sm: '1 1 25%' }, textAlign: 'right' }}>
						<Box sx={{ pt: { xs: 2, md: 1 }, pr: { xs: 2, md: 1 } }}>
							<Typography variant="overline" component="h5" display="block" sx={{ opacity: 0.6 }}>Suivez-nous</Typography>

							<IconButton href="" rel="nofollow noopener" sx={{ color: 'grey.50' }}>
								<InstagramLogo size={36} weight="thin" />
							</IconButton>
						</Box>
					</Box>
				</Box>
			</Paper>

			<Dialog
				maxWidth="md"
				fullWidth
				open={contactFormOpen}
				onClose={() => setContactFormOpen(false)}
				sx={{ '& .MuiDialog-container': { backdropFilter: { xs: 'none', md: 'blur(20px)' } } }}
			>
				<ContactForm onClose={() => setContactFormOpen(false)} />
			</Dialog>

			<Dialog
				maxWidth="md"
				fullWidth
				open={legalNoticesOpen}
				onClose={() => setLegalNoticesOpen(false)}
				sx={{ '& .MuiDialog-container': { backdropFilter: { xs: 'none', md: 'blur(20px)' } } }}
			>
				<LegalNotices onClose={() => setLegalNoticesOpen(false)} />
			</Dialog>

			<Dialog
				maxWidth="md"
				fullWidth
				open={privacyPolicyOpen}
				onClose={() => setPrivacyPolicyOpen(false)}
				sx={{ '& .MuiDialog-container': { backdropFilter: { xs: 'none', md: 'blur(20px)' } } }}
			>
				<PrivacyPolicy onClose={() => setPrivacyPolicyOpen(false)} />
			</Dialog>

			<Dialog
				maxWidth="md"
				fullWidth
				open={shippingReturnOpen}
				onClose={() => setShippingReturnOpen(false)}
				sx={{ '& .MuiDialog-container': { backdropFilter: { xs: 'none', md: 'blur(20px)' } } }}
			>
				<ShippingReturn onClose={() => setShippingReturnOpen(false)} />
			</Dialog>
		</>
	);
};

export default Footer;
