import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { X } from 'phosphor-react';

const ShippingReturn = ({ onClose }) => (
	<>
		<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
			Livraison & retour
			<IconButton edge="end" onClick={onClose}>
				<X />
			</IconButton>
		</DialogTitle>
		<DialogContent>

		</DialogContent>
	</>
);

export default ShippingReturn;
