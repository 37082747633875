import React/*, { useState }*/ from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { X } from 'phosphor-react';
import 'yup-phone';

const validationSchema = yup.object({
	fullname: yup.string().required(`Votre nom complet est requis`),
	email: yup.string().email().required(`L'email est requis.`),
	phone: yup.string().phone('CH', true),
	message: yup.string(),
});

const ContactForm = ({ onClose }) => {
	//const [ loading, setLoading ] = useState(false);

	const formik = useFormik({
		initialValues: {
			fullname: '',
			email: '',
			message: ''
		},
		validationSchema,
		onSubmit: async values => {
			console.log('values', values);
			onClose();
		}
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				Contact
				<IconButton edge="end" onClick={onClose}>
					<X />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<Box mt={3} mb={3}>
					<TextField
						name="fullname"
						label="Prénom et nom"
						variant="standard"
						required
						fullWidth
						value={formik.values.fullname}
						onChange={formik.handleChange}
						error={formik.touched.fullname && Boolean(formik.errors.fullname)}
						helperText={formik.touched.fullname && formik.errors.fullname}
					/>
				</Box>
				<Box mb={3}>
					<TextField
						name="email"
						type="email"
						label="Email"
						variant="standard"
						required
						fullWidth
						value={formik.values.email}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
						helperText={formik.touched.email && formik.errors.email}
					/>
				</Box>
				<Box mb={3}>
					<TextField
						name="phone"
						type="phone"
						label="Téléphone"
						variant="standard"
						fullWidth
						value={formik.values.phone}
						onChange={formik.handleChange}
						error={formik.touched.phone && Boolean(formik.errors.phone)}
						helperText={formik.touched.phone && formik.errors.phone}
					/>
				</Box>
				<Box mb={2}>
					<TextField
						name="message"
						label="Message"
						variant="standard"
						multiline
						fullWidth
						value={formik.values.message}
						onChange={formik.handleChange}
						error={formik.touched.message && Boolean(formik.errors.message)}
						helperText={formik.touched.message && formik.errors.message}
					/>
				</Box>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center' }}>
				<Button variant="outlined" type="submit">
					Envoyer
				</Button>
			</DialogActions>
		</form>
	);
};

export default ContactForm;
