import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Newsletter from './Newsletter';

const Concept = () => (
	<Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, mb: { xs: 4, md: 6, lg: 8, xl: 16 } }}>
		<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: '1 1 50%', order: { xs: 1, lg: 0 }, p: { xs: 2, md: 4, lg: 8, xl: 12 } }}>
			<Typography variant="h3" sx={{ mt: { xs: 4, lg: 0 }, mb: 6 }}>
				Le concept JOYAA
			</Typography>
			<Typography variant="body1" sx={{ mx: 1, mb: 2, textAlign: 'justify' }}>
				D'objet de désir réservé à quelques-uns, JOYAA fait le choix d'offrir un vrai bijou accessible à tous.
			</Typography>
			<Typography variant="body1" sx={{ mx: 1, mb: 2, textAlign: 'justify' }}>
				Comment ? En alliant technologie 3D et savoir-faire artisanal.
			</Typography>
			<Typography variant="body1" sx={{ mx: 1, mb: 2, textAlign: 'justify' }}>
				Les contraintes de la matière n'existant plus, nos artistes 3D peuvent créer hors des limites physiques et offrir des concepts bien plus élaborés.
			</Typography>
			<Typography variant="body1" sx={{ mx: 1, mb: { xs: 4, lg: 8 }, textAlign: 'justify' }}>
				Ensuite, le bijou peut être matérialisé grâce aux dernières technologies d'impressions 3D, et notre équipe de joailliers ajoutent le savoir-faire artisanal nécessaire afin de sublimer le produit.
			</Typography>

			<Newsletter />
		</Box>
		<Box
			sx={{
				flex: '1 1 50%',
				order: { xs: 0, lg: 1 },
				lineHeight: 0,
				filter: 'drop-shadow(0 0 4px rgb(0 0 0 / 16%))',
			}}
		>
			<Box
				component="img"
				src="/about.jpg"
				alt="Le concept JOYAA"
				sx={{
					maxWidth: '100%',
					height: { xs: 'auto', lg: '100%' },
					objectFit: 'cover',
					clipPath: {
						xs: 'polygon(0 5%, 100% 0, 100% 95%, 0% 100%)',
						lg: 'polygon(0 0, 100% 0, 100% 100%, 20% 100%)',
					},
				}}
			/>
		</Box>
	</Box>
);

export default Concept;
