import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'gatsby-theme-material-ui';
import useGlobalState from '../globalState';
import products from '../products.json';
import Footer from '../components/Footer';
import Concept from '../components/Concept';
import CustomMade from '../components/CustomMade';
import HomeBackground from '../components/HomeBackground';
import ProductContainer from '../components/ProductContainer';
import ProductInfosDialog from '../components/ProductInfosDialog';
import '@babylonjs/loaders';

const IndexPage = () => {
	const [ productInfos, setProductInfos ] = useGlobalState('productInfos');

	return (
		<>
			<HomeBackground>
				<Box sx={{ display: { xs: 'block', md: 'none' }, position: 'relative', width: '100%', pt: 8, pb: 16, px: 8, alignSelf: 'flex-start', backdropFilter: 'blur(5px)', maskImage: 'linear-gradient(to bottom, black 0%, black 80%, transparent 100%)' }}>
					<Box sx={{ width: '100%', height: 50, mb: { xs: 0, md: 4 } }}>
						<Link to="/">
							<img src="/logo.svg" style={{ maxHeight: '100%' }} alt="JOYAA" />
						</Link>
					</Box>

					<Typography variant="h4" component="h2" textAlign="center" sx={{ mt: 4, mb: 8 }}>
						Une nouvelle façon de s'offrir des bijoux
					</Typography>

					<Button href="#products" variant="contained" size="large" fullWidth sx={{ textAlign: 'center' }}>Découvrez notre collection</Button>
				</Box>
				{/*<Box sx={{ display: { xs: 'block', md: 'none' }, position: 'relative', width: '100%', mt: 8, py: 16, px: 8, alignSelf: 'flex-start', backdropFilter: 'blur(10px)', maskImage: 'linear-gradient(to bottom, transparent 0%, black 10%, black 90%, transparent 100%)' }}>
					<Box sx={{ width: '100%', height: 50, mb: { xs: 0, md: 4 } }}>
						<Link to="/">
							<img src="/logo.svg" style={{ maxHeight: '100%' }} alt="JOYAA" />
						</Link>
					</Box>

					<Typography variant="h4" component="h2" textAlign="center" sx={{ my: 4 }}>
						Une nouvelle façon de s'offrir des bijoux
					</Typography>

					<Button variant="contained">Découvrez notre collection</Button>
				</Box>*/}
				<Box sx={{ display: { xs: 'none', md: 'block' }, position: 'relative', width: '100%', pt: 8, pb: 4, px: 8, alignSelf: 'flex-start' }}>
					<Box sx={{ width: '100%', height: 50, mb: { xs: 0, md: 4 } }}>
						<Link to="/">
							<img src="/logo.svg" style={{ maxHeight: '100%' }} alt="JOYAA" />
						</Link>
					</Box>

					<Typography variant="h4" component="h2">
						Une nouvelle façon de s'offrir des bijoux
					</Typography>
				</Box>
			</HomeBackground>

			<Box id="products" sx={{ width: '100%', mt: { xs: 4, md: 6, lg: 8 }, mb: { xs: 4, md: 6, lg: 8, xl: 16 }, px: { xs: 2, sm: 8, md: 4, lg: 8, xl: 16 } }}>
				<Grid container spacing={{ xs: 4, lg: 8 }} justifyContent="center">
					{Object.keys(products).map(key => (
						<Grid key={key} item xs={12} md={6} lg={4} xxl={3}>
							<ProductContainer id={key} {...products[key]} />
						</Grid>
					))}
				</Grid>
			</Box>

			<Concept />

			<CustomMade />

			{Object.keys(products).map(key => (
				<ProductInfosDialog
					key={key}
					id={key}
					open={productInfos === key}
					onClose={() => setProductInfos(null)}
					{...products[key]}
				/>
			))}

			<Footer />
		</>
	);
};

/*export const Head = () => (
	<>
		<title>JOYAA</title>
		<meta name="description" content="D'objet de désir réservé à quelques-uns, JOYAA fait le choix d'offrir un vrai bijou accessible à tous." />
	</>
);*/

export default IndexPage;
