import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { X } from 'phosphor-react';
import { Gallery, Item } from 'react-photoswipe-gallery';
import BabylonScene from './BabylonScene';
import 'photoswipe/dist/photoswipe.css';

const ProductInfosDialog = ({ name, description, finish, pictures, model, price, link, open, onClose }) => {
	const [ tab, setTab ] = useState('pictures');
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Dialog
			maxWidth="lg"
			fullWidth
			fullScreen={isMobile}
			open={open}
			onClose={onClose}
			sx={{ '& .MuiDialog-container': { maxWidth: '100vw', backdropFilter: { xs: 'none', md: 'blur(20px)' } } }}
		>
			<DialogTitle component="div" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: { xs: 3, md: 5 }, pt: { xs: 2, md: 4 } }}>
				<Typography variant="h4" component="h2">{name}</Typography>
				<IconButton onClick={onClose} edge="end">
					<X />
				</IconButton>
			</DialogTitle>
			<DialogContent sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, px: { xs: 3, md: 5 }, pb: { xs: 2, md: 4 } }}>
				<Box sx={{ flex: '1 1 50%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', pr: { xs: 0, lg: 4 }, pt: 3 }}>
					<Typography variant="body1" sx={{ mb: 4, textAlign: 'justify' }}>
						{description}
					</Typography>

					<Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', mb: 4 }}>
						<Paper elevation={2} sx={{ px: 2, m: 1, border: '1px solid #BBB' }}>
							<Typography variant="overline">
								Argent 925
							</Typography>
						</Paper>
						<Paper elevation={2} sx={{ px: 2, m: 1, border: '1px solid #BBB' }}>
							<Typography variant="overline">
								{finish}
							</Typography>
						</Paper>
						<Paper elevation={2} sx={{ px: 2, m: 1, border: '1px solid #BBB' }}>
							<Typography variant="overline">
								Collier en corde noire
							</Typography>
						</Paper>
					</Box>

					<Alert severity="info" variant="outlined" sx={{ mb: 4 }}>
						La production du bijou est lancée lors de la réception de votre commande. Le délai de fabrication et de livraison est de 4 à 6 semaines.
					</Alert>

					<Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
						<Typography variant="h5" sx={{ width: '100%' }}>
							{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'chf' }).format(price)}
						</Typography>

						<Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: { xs: 4, md: 0 } }}>
							<Box component="img" src="/stripe.svg" alt="Powered by Stripe" sx={{ height: 20, mr: 2 }} />
							<Button href={link} variant="contained" size="large">
								ACHETER
							</Button>
						</Box>
					</Box>
				</Box>
				<Box sx={{ flex: '1 1 50%', mt: { xs: 4, lg: 0 } }}>
					<Tabs centered value={tab} onChange={(e, value) => setTab(value)} sx={{ pl: 2 }}>
						<Tab value="pictures" label="Photos" />
						<Tab value="3d" label="Modèle 3D" />
					</Tabs>
					<Box hidden={tab !== 'pictures'}>
						<Gallery>
							<ImageList variant="masonry" cols={3} gap={8} sx={{ mt: 3, mb: 2, justifyContent: 'center' }}>
								{pictures.map((picture, i) => (
									<Item
										key={i}
										original={picture.original}
										thumbnail={picture.thumbnail}
										width={picture.width}
										height={picture.height}
										alt={name}
									>
										{({ ref, open }) => (
											<ImageListItem ref={ref} onClick={open}>
												<img
													src={picture.thumbnail}
													loading="lazy"
													alt={name}
													style={{ cursor: 'pointer' }}
												/>
											</ImageListItem>
										)}
									</Item>
								))}
							</ImageList>
						</Gallery>
					</Box>
					<Box hidden={tab !== '3d'}>
						{tab === '3d' ? <BabylonScene model={model} /> : null}
						<Alert severity="info" variant="outlined">
							Le modèle 3D est une représentation approximative du bijou, les détails sont réduits pour être rapidement chargé depuis le site et il est non-texturé.
						</Alert>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default ProductInfosDialog;
