import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const CustomMade = () => (
	<Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, mb: { xs: 4, md: 6, lg: 8, xl: 16 } }}>
		<Box
			sx={{
				flex: '1 1 50%',
				lineHeight: 0,
				filter: 'drop-shadow(0 0 4px rgb(0 0 0 / 16%))',
			}}
		>
			<Box
				component="img"
				src="/about.jpg"
				alt="Créations sur-mesures"
				sx={{
					maxWidth: '100%',
					height: { xs: 'auto', lg: '100%' },
					objectFit: 'cover',
					clipPath: {
						xs: 'polygon(0 5%, 100% 0, 100% 95%, 0% 100%)',
						lg: 'polygon(0 0, 100% 0, 85% 100%, 0% 100%)',
					},
				}}
			/>
		</Box>
		<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: '1 1 50%', p: { xs: 2, md: 4, lg: 8, xl: 12 } }}>
			<Typography variant="h3" sx={{ mt: { xs: 4, lg: 0 }, mb: 6 }}>
				Créations sur-mesures
			</Typography>
			<Typography variant="body1" sx={{ mx: 1, mb: 2, textAlign: 'justify' }}>
				Toute création sur mesure commence par une envie spécifique qui dure parfois depuis des années mais qui n’a jamais été comblée par les vitrines des magasins. Quelle que soit l’idée de départ, la création sur mesure permet de donner vie aux bijoux les plus précieux, ceux imaginés pour combler un être cher ou tout simplement pour se faire plaisir.
			</Typography>
			<Typography variant="body1" sx={{ mx: 1, mb: 2, textAlign: 'justify' }}>
				De la même manière qu’en boutique, nos experts sont à votre entière disposition et vous consacrent toute leur attention afin de comprendre vos souhaits et vos attentes. Et ce d’autant plus lorsqu’il s’agit d’une création sur mesure. Ils s’intéresseront à votre culture, vos goûts, vos influences et vous proposeront un rendu du bijou tel que vous l’avez imaginé.
			</Typography>
			<Typography variant="body1" sx={{ mx: 1, mb: 2, textAlign: 'justify' }}>
				En effet, désireux de répondre à vos demandes avec le plus de précision possible, nos conseillers spécialisés vous accompagnent et restent à votre écoute tout au long du processus d’élaboration du bijou. Nous tâcherons de vous faire suivre l’avancée de la création du bijou grâce aux photos que nous enverrons à chaque étape de la réalisation pour que vous assistiez à la genèse d’un bijou véritablement unique.
			</Typography>
			<Typography variant="body1" sx={{ mx: 1, mb: 2, textAlign: 'justify' }}>
				La création sur mesure est ainsi un service exceptionnel, idéal pour les occasions spéciales, comme les demandes de fiançailles, les mariages ou encore les anniversaires.
			</Typography>
			<Typography variant="body1" sx={{ mx: 1, mb: { xs: 4, lg: 8 }, textAlign: 'justify' }}>
				Laissez libre cours à vos envies !
			</Typography>

			<Button variant="contained" size="large">Contactez-nous pour votre création !</Button>
		</Box>
	</Box>
);

export default CustomMade;
