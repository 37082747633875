import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import useGlobalState from '../globalState';

const ProductContainer = ({ id, name, description, image, price }) => {
	const [ , setProductInfos ] = useGlobalState('productInfos');

	return (
		<Box sx={{ boxShadow: 6, backdropFilter: 'blur(20px) brightness(1.2) saturate(0.3)' }}>
			<CardActionArea onClick={() => setProductInfos(id)}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						background: 'rgba(255, 255, 255, 0.25)',
						textAlign: 'center',
						overflow: 'hidden',
					}}
				>
					<Box sx={{ flex: '1 1 50%' }}>
						<Box
							sx={{
								position: 'relative',
								height: '100%',
								lineHeight: 0,
								clipPath: 'polygon(0 0, 100% 0, 100% 85%, 0% 100%)',
								aspectRatio: '4/3',
							}}
						>
							<Box component="img" src={image} alt={name} sx={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }} />
						</Box>
					</Box>

					<Box
						sx={{
							position: 'relative',
							flex: '1 1 50%',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							px: { xs: 2, md: 4 },
							pt: { xs: 1, md: 2 },
							pb: 4,
							textAlign: 'center',
							zIndex: 1,
						}}
					>
						<Typography variant="h4">
							{name}
						</Typography>
						<Typography variant="overline" sx={{ mb: 1 }}>
							Argent 925
						</Typography>
						<Typography variant="body1" paragraph>
							{description}
						</Typography>
						<Typography variant="h5">
							{new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'chf' }).format(price)}
						</Typography>

						<Box sx={{ mt: { xs: 2, md: 3, xxl: 4 } }}>
							<Button variant="contained" size="large" component="a" onMouseDown={e => e.stopPropagation()} onClick={() => setProductInfos(id)}>
								PLUS D'INFORMATIONS
							</Button>
						</Box>
					</Box>
				</Box>
			</CardActionArea>
		</Box>
	);
}

export default ProductContainer;
